import React from "react"
import { Link } from "gatsby"
import logo from "../../assets/images/logo.png"
import footerMap from "../../assets/images/footer-map.png"
import { useIntl } from "react-intl"

const Footer = () => {
  const intl = useIntl()
  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer-area bg-color">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-footer-widget" style={
                    intl.locale === "ar"
                      ? { textAlign: "right" }
                      : { textAlign: "left" }
                  }>
              <a href="/" className="logo">
                <img src={logo} alt="logo" />
              </a>
              <p>{intl.formatMessage({ id: "footermsg" })} </p>

              <ul className="social-link">
                <li
                  style={
                    intl.locale === "ar"
                      ? { marginLeft: 0, marginRight: 0 }
                      : { marginRight: 0 }
                  }
                >
                  <a
                    href="https://www.facebook.com/ipalmtech"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-facebook"></i>
                  </a>
                </li>
                <li
                  style={
                    intl.locale === "ar"
                      ? { marginLeft: 0 }
                      : { marginRight: 10 }
                  }
                >
                  <a
                    href="https://twitter.com/ipalmtech"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-twitter"></i>
                  </a>
                </li>
                <li
                  style={
                    intl.locale === "ar"
                      ? { marginLeft: 0 }
                      : { marginRight: 10,  }
                  }
                >
                  <a
                    href="https://www.instagram.com/ipalmtech"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-instagram"></i>
                  </a>
                </li>
                <li
                  style={
                    intl.locale === "ar"
                      ? { marginLeft: 0, marginRight: 10 }
                      : { marginRight: 0 }
                  }
                >
                  <a
                    href="https://www.linkedin.com/company/ipalm"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-2 col-sm-6">
            <div className="single-footer-widget pl-5">
              <h3 style={
                    intl.locale === "ar"
                      ? { textAlign: "right" }
                      : { textAlign: "left" }
                  }>{intl.formatMessage({ id: "footerexplore" })} </h3>

              <ul className="footer-links-list" style={
                    intl.locale === "ar"
                      ? { textAlign: "right" }
                      : { textAlign: "left" }
                  }>
                <li>
                  <Link to="/">{intl.formatMessage({ id: "home" })} </Link>
                </li>
                <li>
                  <Link to="/about-us">
                    {intl.formatMessage({ id: "aboutus" })}{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    {intl.formatMessage({ id: "contact" })}{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-2 col-sm-6">
            <div className="single-footer-widget">
              <h3 style={
                    intl.locale === "ar"
                      ? { textAlign: "right" }
                      : { textAlign: "left" }
                  }>{intl.formatMessage({ id: "services" })} </h3>

              <ul className="footer-links-list">
                <li>
                  <Link to="/">
                    {intl.formatMessage({ id: "businessanalysis" })}{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/data-analytics-ai-startup">
                    {intl.formatMessage({ id: "digitaltransformation" })}{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/digital-marketing-agency">
                    {intl.formatMessage({ id: "artificialintelligence" })}{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-footer-widget">
              <h3 style={
                    intl.locale === "ar"
                      ? { textAlign: "right" }
                      : { textAlign: "left" }
                  }>{intl.formatMessage({ id: "address" })} </h3>

              <ul className="footer-contact-info" style={
                    intl.locale === "ar"
                      ? { textAlign: "right" }
                      : { textAlign: "left" }
                  }>
                <li
                  style={
                    intl.locale === "ar"
                      ? { textAlign: "right", display: "inline-block" }
                      : { textAlign: "left", display: "inline-block" }
                  }
                >
                  <i className="bx bx-map"></i>
                  <a href="https://www.google.tn/maps/place/iPalm/@33.9792108,8.2075548,17z/data=!4m12!1m6!3m5!1s0x1257c3daead5eb73:0xdfa868c84e5c7816!2siPalm!8m2!3d33.9792108!4d8.2097488!3m4!1s0x1257c3daead5eb73:0xdfa868c84e5c7816!8m2!3d33.9792108!4d8.2097488?hl=en&authuser=0">
                    {intl.formatMessage({ id: "addresslocation" })} <br />{" "}
                    {intl.formatMessage({ id: "addresscountry" })}{" "}
                  </a>
                </li>
                <li
                  style={
                    intl.locale === "ar"
                      ? { textAlign: "right", display: "inline-block" }
                      : { textAlign: "left", display: "inline-block" }
                  }
                >
                  <i className="bx bx-phone-call"></i>
                  <a href="tel:+21655767884">(+216) 55 767 884</a>
                </li>
                <li>
                  <i className="bx bx-envelope"></i>
                  <a href="mailto:hello@ipalm.tn">hello@ipalm.com</a>
                </li>
                <li>
                  <i className="bx bxs-inbox"></i>
                  <a href="tel:+21655767884">(+216) 55 767 884</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-bottom-area">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <p>
                {intl.formatMessage({ id: "copyright" })} @{currentYear}{" "}
                <strong>iPalm</strong>{" "}
                {intl.formatMessage({ id: "copyrightreserved" })}{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-map">
        <img src={footerMap} alt="footer-logo" />
      </div>
    </footer>
  )
}

export default Footer
